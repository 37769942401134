import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDomainDeviceGroups } from "../../../Utils/Data/hooks/server";
import { DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Tooltip, Typography } from "@material-ui/core";
import { ListField } from "../GlobalDeviceQueryField";
import { Halign } from "../../Flex";
import * as PapaParse from "papaparse";
import { useNotification } from "../../../Notification";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileUpload, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { TrafficStateInfoDialog } from "../Rnr/TrafficStateInfoDialog";
import { CommonDialog } from "../../CommonDialog";
import { RenderField, useFormCommon } from "../FormCommon";
import { makeStyles } from "@material-ui/core/styles";
import { DialogSaveCancelButtonsOnSubmit } from "../../MasterDetail/DialogSaveCancelButtons";
import { Store } from "react-notifications-component";

function validateData(data, projectDevices, fileInfo, currentValue, replace) {
    if (data.length < 2) {
        return { errors: [{ error: "invalid_data" }] };
    }

    if (data[0].length < 5 || data[0][0] !== "RNR" || data[0][1] !== "NR" || data[0][2] !== "Znacka" || data[0][3] !== "Dolezitost" || data[0][4] !== "Oneskorenie") {
        return { errors: [{ error: "invalid_data_header" }] };
    }

    const symbols = data[0].slice(5);

    let ret = [];
    let errors = [];

    const validateItem = (item) => {
        const device = projectDevices.find((device) => device?.config?.rnr_id === item.rnr_id);
        if (!device) {
            return { error: "device_not_found", errorParams: { rnr_id: item.rnr_id } };
        }
        const endDevice = device?.activeConfig?.slices?.[item.nr_id]?.devices?.[item.device_id];
        if (!endDevice) {
            return {
                error: "end_device_not_found",
                errorParams: { rnr_id: item.rnr_id, nr_id: item.nr_id, device_id: item.device_id },
            };
        }

        for (const symbol of item.symbols) {
            if (endDevice.supported_symbols?.indexOf(symbol) === -1) {
                return {
                    error: "invalid_symbol",
                    errorParams: { rnr_id: item.rnr_id, nr_id: item.nr_id, device_id: item.device_id, symbol },
                };
            }
        }
    };
    for (let i = 1; i < data.length; i++) {
        if (data[i].length < 5) {
            continue;
        }

        const row = data[i];
        if (isNaN(row[4])) {
            errors.push({ error: "invalid_data_delay", errorParams: { delay: row[4], row: i } });
            continue;
        }

        let item = {
            rnr_id: row[0],
            nr_id: row[1],
            device_id: row[2],
            is_mandatory: row[3] === "M",
            delay: parseInt(row[4]) || 0,
            symbols: row.slice(5).flatMap((value, idx) => {
                if (value === "X") {
                    return symbols[idx];
                } else {
                    return [];
                }
            }),
        };

        const error = validateItem(item);
        if (error) {
            errors.push({ ...error, errorParams: { ...error.errorParams, row: i } });
        } else {
            ret.push(item);
        }
    }

    const name = fileInfo.name.replace(/\.[^/.]+$/, "");

    if (!replace && currentValue.data.find((item) => item.name === name)) {
        errors.push({ error: "duplicate_name" });
    }

    return { data: ret, errors, name };
}

function onItemAdd(onError, onFileLoaded) {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";

    input.onchange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileInfo = {
                name: file.name,
                size: file.size,
                type: file.type,
            };

            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");

            reader.onload = (readerEvent) => {
                const csvData = PapaParse.parse(readerEvent.target.result, { error: onError, encoding: "UTF-8" });
                onFileLoaded(csvData?.data ?? [], fileInfo, file);
            };
        }
    };

    input.click();
}

const useStyles = makeStyles((theme) => ({
    formGroup: {
        display: "flex",
        flexDirection: "row",
    },
    notification: {
        width: "1000px !important",
    },
}));

const formConfig = [
    {
        id: "displayName",
        type: "TextField",
        props: {
            required: false,
        },
    },
    {
        id: "priority",
        type: "NumberField",
        defaultValue: "",
        props: {
            minValue: 0,
            maxValue: 65535,
        },
    },
    {
        id: "note",
        type: "TextAreaField",
        props: {
            required: false,
        },
    },
    {
        id: "hidden",
        type: "CheckboxField",
        props: {
            required: false,
        },
    },
    {
        id: "currentIcon",
        type: "FileSelectionField",
        props: {
            accept: ".svg",
        },
    },
    {
        id: "pendingIcon",
        type: "FileSelectionField",
        props: {
            accept: ".svg",
        },
    },
    {
        id: "selectableIcon",
        type: "FileSelectionField",
        props: {
            accept: ".svg",
        },
    },
    {
        id: "fallbackIcon",
        type: "FileSelectionField",
        props: {
            accept: ".svg",
        },
    },
];

function SafeStateField({ data, value, onChange, disabled }) {
    const intl = useIntl();

    return (
        <>
            <FormLabel component="legend" disabled={disabled}>
                {formatForId(intl, `RnrTrafficStatesFieldList.safe_state`)}
            </FormLabel>
            <RadioGroup
                name="safe_state"
                value={value}
                style={{ padding: "1em", flexDirection: "row" }}
                onChange={(evt) => {
                    onChange(evt.target.value);
                }}
                disabled={disabled}
            >
                {data.map((item) => (
                    <FormControlLabel value={item.name} control={<Radio />} label={item.name} disabled={disabled} />
                ))}
            </RadioGroup>
        </>
    );
}

function EditTrafficStateDialog({ onClose, data, open, onChange, trafficStates }) {
    const intl = useIntl();
    const initialData = React.useMemo(() => (data ? data : {}), [data]);
    const classes = useStyles();

    const onSubmit = async (values, context) => {
        onChange(data?.name, values);
    };

    const formik = useFormCommon(formConfig, initialData, onSubmit, true);

    const trafficStatesComputed = useMemo(() => trafficStates?.filter((item) => item.name !== data?.name), [trafficStates, data?.name]);

    if (!data) {
        return null;
    }

    return (
        <CommonDialog open={open} onClose={onClose} title={formatForId(intl, `RnrTrafficStatesFieldList.formDialog.title`)} maxWidth={"lg"}>
            <DialogTitle>{data?.name}</DialogTitle>
            <DialogContent>
                <form autoComplete={"off"}>
                    <FormControl fullWidth classes={{ root: classes.mainForm }}>
                        {formConfig.map((item, idx) =>
                            RenderField({
                                intl,
                                changeHandle: (evt) => {
                                    formik.handleChange(evt);
                                },
                                item,
                                formik,
                                formId: "RnrTrafficStatesFieldList.formDialog",
                                editable: true,
                                classes,
                            })
                        )}

                        <SafeStateField disabled={false} onChange={(value) => formik.setFieldValue("safe_state", value)} data={trafficStatesComputed} value={formik.values.safe_state} />
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <DialogSaveCancelButtonsOnSubmit onSubmit={formik.submitForm} onClose={onClose} />
            </DialogActions>
        </CommonDialog>
    );
}

export function RnrTrafficStatesField({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete, formik }) {
    const intl = useIntl();
    const notification = useNotification();
    const { deviceGroups, refetch } = useDomainDeviceGroups(formik?.values?.domain_id);
    const [infoDialogData, setInfoDialogData] = React.useState(null);
    const [editDialogData, setEditDialogData] = React.useState(null);
    const classes = useStyles();

    useEffect(() => refetch(), [formik?.values?.domain_id]);

    const projectDevices = useMemo(() => {
        let devices = [];
        for (const group of deviceGroups) {
            for (const device of group.devices) {
                const driverConfiguration = device.driver_configuration_json ? JSON.parse(device.driver_configuration_json) : null;
                const activeConfig = driverConfiguration && driverConfiguration.active_configuration ? JSON.parse(driverConfiguration.active_configuration) : null;
                if (device?.running_device_id === formik?.values?.id) {
                    devices.push({
                        device,
                        config: driverConfiguration,
                        activeConfig: activeConfig,
                    });
                }
            }
        }

        return devices;
    }, [deviceGroups]);

    const currentValue = useMemo(() => {
        if (value) {
            return JSON.parse(value);
        } else {
            return {
                data: [],
                safe_state: null,
            };
        }
    }, [value]);

    const onItemChanged = (name, data) => {
        const newData = currentValue.data.map((item) => {
            if (item.name === name) {
                return { ...item, ...data };
            }
            return item;
        });

        changeHandle({
            target: {
                name: fieldId,
                value: JSON.stringify({ ...currentValue, data: newData }),
            },
        });
    };

    const showErrors = (errors) => {
        let errorsText = "";

        for (const error of errors) {
            errorsText += formatForId(intl, `forms.RnrTrafficStatesFieldList.error.${error.error}`, error.errorParams) + " \n";
        }

        Store.addNotification({
            message: <Typography style={{ whiteSpace: "pre-wrap" }}>{errorsText}</Typography>,
            type: "warning",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 40000,
                onScreen: true,
                click: true,
                showIcon: true,
            },
        });
    };

    const valueSorted = useMemo(() => {
        return currentValue.data.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => (a.priority && b.priority ? a.priority - b.priority : 0));
    }, [currentValue.data]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl}>
                <FormLabel component="legend" disabled={disabled} style={{ paddingBottom: 8 }}>
                    {label}
                </FormLabel>
                <ListField
                    deleteDialog
                    component={({ value, idx, onChange }) => (
                        <Halign>
                            <FormLabel component={"legend"} disabled={disabled}>
                                {value.name}
                            </FormLabel>
                        </Halign>
                    )}
                    value={valueSorted}
                    localizationContext={"RnrTrafficStatesFieldList"}
                    disabled={disabled}
                    onChange={(data) => {
                        changeHandle({ target: { name: fieldId, value: JSON.stringify({ ...currentValue, data }) } });
                    }}
                    onCustomRowAdd={(onChange) =>
                        onItemAdd(
                            () => {},
                            (data, fileInfo) => {
                                const validatedData = validateData(data, projectDevices, fileInfo, currentValue, false);
                                if (validatedData.errors.length > 0) {
                                    showErrors(validatedData.errors);
                                } else {
                                    onChange(validatedData);
                                }
                            }
                        )
                    }
                    onRenderCustomAction={(item) => (
                        <>
                            <Tooltip title={formatForId(intl, `RnrTrafficStatesFieldList.edit`)}>
                                <IconButton
                                    onClick={(e) => {
                                        setEditDialogData(item);
                                    }}
                                    disabled={disabled}
                                >
                                    <FontAwesomeIcon icon={faEdit} size={"xs"} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={formatForId(intl, `FileSelectionField.upload`)}>
                                <IconButton
                                    onClick={(e) => {
                                        onItemAdd(
                                            () => {},
                                            (data, fileInfo) => {
                                                const validatedData = validateData(data, projectDevices, fileInfo, currentValue, true);
                                                if (validatedData.errors.length > 0) {
                                                    showErrors(validatedData.errors);
                                                } else {
                                                    onItemChanged(item.name, { data: validatedData.data });
                                                }
                                            }
                                        );
                                    }}
                                    disabled={disabled}
                                >
                                    <FontAwesomeIcon icon={faFileUpload} size={"xs"} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={formatForId(intl, `RnrTrafficStatesFieldList.info`)}>
                                <IconButton
                                    onClick={(e) => {
                                        setInfoDialogData(item);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} size={"xs"} />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                />
                {currentValue.data.length !== 0 && (
                    <SafeStateField
                        disabled={disabled}
                        onChange={(value) => {
                            changeHandle({
                                target: {
                                    name: fieldId,
                                    value: JSON.stringify({ ...currentValue, safe_state: value }),
                                },
                            });
                        }}
                        data={currentValue.data}
                        value={currentValue.safe_state}
                    />
                )}
            </FormControl>
            <TrafficStateInfoDialog onClose={() => setInfoDialogData(null)} open={infoDialogData !== null} data={infoDialogData} />
            <EditTrafficStateDialog
                onClose={() => setEditDialogData(null)}
                data={editDialogData}
                open={editDialogData !== null}
                onChange={(name, data) => {
                    onItemChanged(name, data);
                    setEditDialogData(null);
                }}
                trafficStates={currentValue.data}
            />
        </div>
    );
}
