import React from "react";
import { Valign } from "../../../Components/Flex";
import { useRnrTrafficState, useRnrViewContext } from "../../../Utils/Data/actions/RnrData";
import { TrafficStatesControl } from "./TrafficStateControl";
import { RequirePermission } from "../../../Utils/Permissions/RequirePermission";
import { SvgView } from "./SvgView";
import LoadingScope from "../../../Components/LoadingScope";
import { useActiveView, useViewCustomConfig } from "../../../Utils/Data/hooks/deviceDataView";

export function TrafficStateControlContainer({ trafficStates, rnrTrafficStateData, reloadData, rnrDriverId, domainId, endDevices, slices }) {
    return (
        <Valign
            style={{
                top: 56,
                position: "absolute",
                height: 120,
                width: "100%",
                padding: 4,
            }}
        >
            <TrafficStatesControl
                trafficStates={trafficStates}
                rnrTrafficStateData={rnrTrafficStateData}
                reloadData={reloadData}
                rnrDriverId={rnrDriverId}
                domainId={domainId}
                endDevices={endDevices}
                slices={slices}
            />
        </Valign>
    );
}

function RnrViewLocal({ rnrViewContext }) {
    const { trafficStates, rnrDriverId, endDevices, domainId, rnrDevices, slices, historyLoading, historyData } = rnrViewContext;
    const { rnrTrafficStateData, reloadData, error, loading } = useRnrTrafficState(rnrDriverId, historyData);
    const view = useActiveView();
    const viewConfig = useViewCustomConfig();

    return (
        <RequirePermission permission={"rnr__view_state"} domainID={domainId}>
            <LoadingScope loading={loading} error={error} showLoading={false} dialog={false}>
                <>
                    <div
                        style={{
                            backgroundColor: viewConfig?.background_color ? viewConfig.background_color : "#606060",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <SvgView endDevices={endDevices} rnrDevices={rnrDevices} rnrTrafficStateData={rnrTrafficStateData} refreshTrigger={view?.id} slices={slices} />
                    </div>

                    <TrafficStateControlContainer
                        trafficStates={trafficStates}
                        rnrDriverId={rnrDriverId}
                        domainId={domainId}
                        endDevices={endDevices}
                        rnrTrafficStateData={rnrTrafficStateData}
                        reloadData={reloadData}
                        slices={slices}
                    />
                </>
            </LoadingScope>
        </RequirePermission>
    );
}

export default function RnrView({}) {
    const rnrViewContext = useRnrViewContext();
    return <RnrViewLocal rnrViewContext={rnrViewContext} />;
}
